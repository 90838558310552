import { Section } from '../../components';
import { Quote } from '../../components/paragraph/Quote';
import SideGallery from '../../components/SideGallery';
import PlaylistPreview from './PlaylistPreview';

export const StaticContent = () => {
    return (
        <Section title="Martin Meier presents:">
            <SideGallery
                images={[
                    {
                        link: '/images/meeting-sierra-ferrell.JPG',
                        title: 'Erlebnisse',
                    },
                    {
                        link: '/images/SierraFerrell_TrailOfFlowers.jpg',
                        title: 'Sierra Ferrell - Trail Of Flowers',
                    },
                ]}
                title="Sierra Ferrell"
            >
                <p style={{ fontWeight: 'bold', marginBottom: 20 }}>
                    Seit Jahren ist sie meine Lieblingsk&uuml;nstlerin. <strong>Sierra Ferrell</strong> l&auml;sst mit
                    ihrem neuen Album <strong>Trail Of Flowers</strong> aufhorchen. Es ist f&uuml;r mich schon heute
                    eine der herausragenden Produktionen f&uuml;r die Pr&auml;mierung zum Album des Jahres.
                </p>
                <p style={{ marginBottom: 20 }}>
                    Es ist praktisch unm&ouml;glich und schliesslich unn&ouml;tig, die Musik von{' '}
                    <strong>Sierra Ferrell</strong> zu beschreiben geschweige denn zu kategorisieren. F&uuml;r mich ist
                    ihre Musik die Summe vieler «altmodischer» Musikstile, die allesamt bei{' '}
                    <strong>Sierra Ferrell</strong> zu einem Ganzen verschmolzen werden. Man kann sie kaum als
                    Honky-Tonk K&uuml;nstlerin, als Mountain Music- oder Bluegrass Puristin oder als Cabaret Jazz
                    S&auml;ngerin aus den 30er Jahren bezeichnen. Die Antwort ist ganz einfach: sie ist das alles und
                    vieles mehr. Diese Frau ist ein musikalisches Genie. Sie bewegt sich musikalisch auf einem Niveau,
                    von dem die meisten nur tr&auml;umen k&ouml;nnen. Die Art und Weise wie sie singt, Melodien
                    verwendet, Stile interpretiert und dabei alles so nat&uuml;rlich und leicht erscheinen l&auml;sst,
                    ist einfach ph&auml;nomenal.
                </p>
                <p style={{ marginBottom: 20 }}>
                    Es ist eine Tatsache, dass <strong>Sierra Ferrells</strong> Live-Auftritte solch hohe Erwartungen
                    wecken, die in ihren Studioproduktionen nie erreicht werden k&ouml;nnen. Ihre fesselnde Art, ihre
                    Improvisationen, ihre Energie und ihre Spontanit&auml;t kann ein Studioalbum nie einfangen.
                    <strong>Sierra Ferrell</strong> muss man ganz einfach live erleben und erfahren, ja im richtigen
                    Leben kennenlernen.
                </p>
                <p style={{ marginBottom: 20 }}>
                    Ich hatte zum Gl&uuml;ck das Vergn&uuml;gen, <strong>Sierra Ferrell</strong> schon einige mal live
                    zu h&ouml;ren und zu erleben. Ich weiss also von was ich schreibe. Ihre Auftritte sind phantastisch,
                    grossartig und sehr unterhaltsam. Musikalisch ist <strong>Sierra Ferrell</strong> ein wahrer Schatz.
                </p>
                <p style={{ marginBottom: 20 }}>
                    <strong>Trail Of Flowers</strong> ist ein Album, das einerseits tief verwurzelt ist in der
                    Vergangenheit und andererseits auch in die heutige Zeit passt und alle Musikfans anspricht. Wir alle
                    tun uns einen grossen Gefallen und kaufen <strong>Trail Of Flowers</strong>. Es ist ein
                    eindrucksvolles und sensationelles Album einer grandiosen und hervorragenden K&uuml;nstlerin.
                    Zuhause ist es die perfekte Begleitung und die Musik l&auml;sst uns nicht mehr los.
                </p>
                <p style={{ fontWeight: 'bold', marginBottom: 20 }}>
                    Ach übrigens: Soeben gewann Sierra Ferrell die Auszeichnungen für Artist of the Year sowie für das
                    Album des Jahres an den Americana Music Association Awards.
                </p>
            </SideGallery>
            {/* <SideGallery
                images={[
                    {
                        link: '/images/VonTexasNachRicolaTown.jpg',
                        title: 'Von Texas nach Ricola Town',
                    },
                ]}
                title="Von Texas nach Ricola Town"
            >
                Gerne verweise ich auf den am 13.11.2023 in der BZ erschienenen Artikel:
                <br />
                <br />
                <a href="/images/VonTexasNachRicolaTown.jpg" title="From Texas to Ricola Town">
                    Von Texas nach Ricola Town
                </a>
            </SideGallery> */}
            <div style={{ clear: 'both' }}></div>
            <SideGallery
                images={[
                    { link: 'images/flatlanders_butch.jpg', title: 'Flatlanders - Butch Hancock' },
                    { link: 'images/flatlanders_jimmie_dale.jpg', title: 'Flatlanders - Jimmie Dale Gilmore' },
                    { link: 'images/flatlanders_joe_ely.jpg', title: 'Flatlanders - Flatlanders - Joe Ely' },
                    { link: 'images/dale.jpg', title: 'Dale Watson' },
                ]}
                title="Konzerte"
            >
                <>
                    <ul>
                        <li>Konzerte mit vornehmlich amerikanischen K&uuml;nstlern aus verschiedenen Musiksparten</li>
                        <li>qualitativ hochklassige K&uuml;nstler*Innen</li>
                        <li>hochstehende Live-Musik</li>
                        <li>das Faszinierende, das Unmittelbare und das Sch&ouml;ne der Live-Musik</li>
                        <li>
                            pers&ouml;nliche Begegnungen mit anderen Menschen und nat&uuml;rlich auch mit den Musikern
                        </li>
                    </ul>
                    <p className="paragraph">
                        Lasst euch mitreissen und begeistern! Ein Besuch der Konzerte lohnt sich allemal. Bitte
                        tr&auml;gt diese Botschaft weiter. Besten Dank.
                    </p>
                    <Quote style={{ marginTop: 20 }} source="Aristoteles">
                        Das Wesen der Musik ist es, Freude zu bereiten.
                    </Quote>
                </>
            </SideGallery>
            <div style={{ clear: 'both' }}></div>

            <SideGallery
                images={[
                    { link: 'images/fnf_fondue.jpg', title: 'Fans- &amp; Friends, Fondue' },
                    { link: 'images/fnf_lungernsee.jpg', title: 'Fans- &amp; Friends, Lungernsee' },
                ]}
                title="Fans- & Friends Tourneen"
            >
                <>
                    <p>
                        F&uuml;r ausgew&auml;hlte K&uuml;nsterInnen organisiere ich sogenannte Fans- & Friends Tourneen
                        in der Schweiz und im angrenzenden Ausland. Dabei bringen die US-K&uuml;nstler jeweils ihre
                        eigenen handverlesenen Fans und Freunde mit und ich gestalte den jeweiligen Aufenthalt und den
                        Tourneeplan.
                    </p>
                    <br />
                    <p>
                        Bisher habe ich drei dieser sehr speziellen und unvergesslichen Tourneen durchf&uuml;hren
                        k&ouml;nnen, n&auml;mlich:
                        <ul>
                            <li>2012: Albert &amp; Gage</li>
                            <li>2018: Courtney Patton &amp; Jason Eady</li>
                            <li>2019: Thomas Michael Riley</li>
                        </ul>
                    </p>
                </>
            </SideGallery>
            <div style={{ clear: 'both' }}></div>
            {/* <SideGallery
                images={[
                    {
                        link: '/images/meeting-sierra-ferrell.JPG',
                        title: 'Erlebnisse',
                    },
                ]}
                title="Erlebnisse"
            >
                <>
                    <h3>Meeting Sierra Ferrell </h3>
                    <p className="paragraph">
                        I experienced another highlight in my life and met the absolutely fantastic Sierra Ferrell. I
                        was so lucky to meet her in person and to invite her for a drink.
                    </p>{' '}
                    <p className="paragraph">
                        She’s definitely my favorite artist right now. She’s a true treasure, a genuine creative soul
                        with an honest spirit.
                    </p>
                    <p className="paragraph">
                        Her live performances are amazing, incredible and simply brilliant. Don’t miss her when she’s
                        touring in your neighborhood. She’s simply one of the best.
                    </p>
                </>
            </SideGallery> */}
            <div style={{ clear: 'both', marginTop: 30 }}></div>
            <PlaylistPreview />
            <div style={{ clear: 'both' }}></div>
        </Section>
    );
};

export default StaticContent;
