import { NavLink } from 'react-router-dom';
import { RightColumn } from '../../components/RightColumn';
import { RightContent } from '../../components/RightContent';
import { RightContentFrame } from '../../components/RightContentFrame';
import { PageRoutes } from '../../page-routes';
import TicketReservationSide from '../../components/TicketReservationSide';
import { useEffect, useState } from 'react';
import { ReservationService } from '../../services';
import { LinearProgress } from '@mui/material';

const service = new ReservationService();

export const RightSide = () => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [years, setYears] = useState<number[]>([]);

    const loadYears = async () => {
        setLoading(true);
        try {
            const years = await service.getArchiveYears();

            setYears(years);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadYears();
    }, []);

    return (
        <>
            <RightColumn>
                <RightContent title="NEWS">
                    <RightContentFrame title="Oktober 2024">Playlist, Oktober</RightContentFrame>
                </RightContent>
                <TicketReservationSide />
                <RightContent title="KONZERTE DER LETZTEN JAHRE">
                    <>
                        {isLoading ? (
                            <LinearProgress></LinearProgress>
                        ) : (
                            <>
                                <p>und Konzerte der laufenden Saison</p>
                                <div className="frame_2-">
                                    <ul>
                                        {years?.map((y, k) => (
                                            <li key={k}>
                                                <NavLink to={`${PageRoutes.Archive}/${y}`} title={`Konzerte ${y}`}>
                                                    Konzerte {y}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <img
                                        src="/images/rechte_spalte_text_01.png"
                                        style={{ opacity: 0.9, width: 200 }}
                                        title="From Austin to Laufen"
                                        alt="From Austin to Laufen"
                                    />
                                </div>
                            </>
                        )}
                    </>
                </RightContent>
                <RightContent title="BISHERIGE KÜNSTLER/INNEN">
                    <>
                        <div className="frame_2-" style={{ marginBottom: 40 }}>
                            <ul>
                                <li>
                                    <a href="archive/#albertandgage">Albert &amp; Gage</a>
                                </li>
                                <li>
                                    <a href="archive/#thebandofheathens17">Band of Heathens </a>
                                </li>
                                <li>
                                    <a href="archive/#mikeblakely">Mike Blakely</a>
                                </li>
                                <li>
                                    <a href="archive/#suzybogguss">Suzy Bogguss</a>
                                </li>
                                <li>
                                    <a href="archive/#colinbrooks">Colin Brooks</a>
                                </li>
                                <li>
                                    <a href="archive/#alisonbrownacousticquartet16">Alison Brown Acoustic Quartet</a>
                                </li>
                                <li>
                                    <a href="archive/#billchambersrobynludwick">Bill Chambers</a>
                                </li>
                                <li>
                                    <a href="archive/#deadman">Deadman</a>
                                </li>
                                <li>
                                    <a href="archive/#delcastillo">Del Castillo</a>
                                </li>
                                <li>
                                    <a href="archive/#joeely">Joe Ely</a>
                                </li>
                                <li>
                                    <a href="archive/#theflatlanders">The Flatlanders</a>
                                </li>
                                <li>
                                    <a href="archive/#elizagilkyson">Eliza Gilkyson</a>
                                </li>
                                <li>
                                    <a href="archive/#jameshand">James Hand</a>
                                </li>
                                <li>
                                    <a href="archive/#highplainsjamboree16">High Plains Jamboree</a>
                                </li>
                                <li>
                                    <a href="archive/#kruegerbrothers14">Kr&uuml;ger Brothers</a>
                                </li>
                                <li>
                                    <a href="archive/#jameslann">James Lann</a>
                                </li>
                                <li>
                                    <a href="archive/#brennenleighnoelmckay18">Brennen Leigh & Noel McKay</a>
                                </li>
                                <li>
                                    <a href="archive/#boblivingston">Bob Livingston</a>
                                </li>
                                <li>
                                    <a href="archive/#billchambersrobynludwick">Robyn Ludwick</a>
                                </li>
                                <li>
                                    <a href="archive/#clairelynchband16">Claire Lynch Band</a>
                                </li>
                                <li>
                                    <a href="archive/#jameshand">Tracie Lynn</a>
                                </li>
                                <li>
                                    <a href="archive/#madisonviolet">Madison Violet</a>
                                </li>
                                <li>
                                    <a href="archive/#kelleymickwee17">Kelley Mickwee</a>
                                </li>
                                <li>
                                    <a href="archive/#mickyandthemotorcars">Micky and the Motorcars</a>
                                </li>
                                <li>
                                    <a href="archive/#milkdrive">MilkDrive</a>
                                </li>
                                <li>
                                    <a href="archive/#courtneypattonjasoneady">
                                        Courtney Patton & <br />
                                        Jason Eady
                                    </a>
                                </li>
                                <li>
                                    <a href="archive/#recklesskelly">Reckless Kelly</a>
                                </li>
                                <li>
                                    <a href="archive/#paulinereese">Pauline Reese</a>
                                </li>
                                <li>
                                    <a href="archive/#donrigsby">Don Rigsby</a>
                                </li>
                                <li>
                                    <a href="archive/#thomasmichaelriley">Thomas Michael Riley</a>
                                </li>
                                <li>
                                    <a href="archive/#tomrussell">Tom Russell</a>
                                </li>
                                <li>
                                    <a href="archive/#sarahpierceband">Sarah Pierce Band</a>
                                </li>
                                <li>
                                    <a href="archive/#tonivescoliundband16">Amanda Shaw</a>
                                </li>
                                <li>
                                    <a href="archive/#scottsouthworth19">Scott Southworth</a>
                                </li>
                                <li>
                                    <a href="archive/#jamielinwilsonsunnysweeney16">Sunny Sweeney</a>
                                </li>
                                <li>
                                    <a href="archive/#colinbrooks">Owen Temple</a>
                                </li>
                                <li>
                                    <a href="archive/#texassweethottrio">Texas Sweet Hot Trio</a>
                                </li>
                                <li>
                                    <a href="archive/#tonivescoliundband">Toni Vescoli und B&auml;nd</a>
                                </li>
                                <li>
                                    <a href="archive/#rhondavincentandtherage">Rhonda Vincent &amp; The Rage</a>
                                </li>
                                <li>
                                    <a href="archive/#dalewatson">Dale Watson</a>
                                </li>
                                <li>
                                    <a href="archive/#jamielinwilsonsunnysweeney16">Jamie Lin Wilson</a>
                                </li>
                                <li>
                                    <a href="archive/#carolynwonderland">Carolyn Wonderland</a>
                                </li>
                            </ul>
                            <div>
                                <img
                                    src="images/rechte_spalte_text_02_kuenstler.png"
                                    style={{ opacity: 1, width: 230, marginTop: 20, marginBottom: 20 }}
                                    title="From Austin to Laufen"
                                    alt="From Austin to Laufen"
                                />
                            </div>
                        </div>
                    </>
                </RightContent>
            </RightColumn>
        </>
    );
};

export default RightSide;
